<template>
  <page-view>
    <div class="cash_header_wrapper">
      <a-row :gutter="48" class="row">
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: 0, marginRight: '14px' }"
        >
          <div class="filter_item">
            <span>用户名</span>
            <a-input
              placeholder="用户名"
              class="filter_input"
              v-model.trim="queryParam.username"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: 0, marginRight: '56px' }"
        >
          <div class="filter_item">
            <span>支付宝登录名</span>
            <a-input
              placeholder="支付宝登录名"
              class="filter_input"
              v-model.trim="queryParam.alipayAccount"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: 0, marginRight: '44px' }"
        >
          <div class="filter_item">
            <span>支付宝姓名</span>
            <a-input
              placeholder="支付宝姓名"
              class="filter_input"
              v-model.trim="queryParam.alipayName"
            />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: 0 }">
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="$refs.table.refresh(false)"
              >查询</a-button
            >
            <a-button @click="resetQueryParam">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="cash_table_wrapper">
      <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
        <span slot="serial" slot-scope="text, record, index">{{
          index + 1
        }}</span>
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.uid)"
              @mouseover="handleMouseover(record)"
              @mouseout="handleMouseout(record)"
            >
              {{
                text.substring(0, 4) +
                  "……" +
                  text.substring(text.split("").length - 4)
              }}
              <span class="uid_child" v-show="record.show">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>

        <template slot="balanceCash" slot-scope="text">
          <span style="color:#F93244">{{ text }}</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a :disabled="edit" @click="pass(record, 'BALANCE_REFUND')">通过</a>
          <a
            :disabled="edit"
            @click="pass(record, 'BALANCE_REFUND_FAILURE')"
            style="margin-left: 24px;color:#F93244"
            >拒绝</a
          >
        </template>
      </s-table>
    </div>
    <a-modal
      title="拒绝内容填写"
      :width="480"
      :visible="visible"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <div class="input_group">
        <span>拒绝理由：</span>
        <a-input class="top5" placeholder="请输入拒绝理由" v-model="comment" />
      </div>
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from "@/components";
import { PageView } from "@/layouts";
import {
  accountWithdrawPass,
  queryWithdrawListApi,
  handleWithdrawResult
} from "@api";
import { formatDate } from "@/utils/util";

export default {
  data() {
    return {
      comment: "",
      visible: false,
      edit: false,
      record: {},
      queryParam: {},
      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "serial" }
        },
        {
          title: "用户ID",
          dataIndex: "uid",
          scopedSlots: { customRender: "uid" }
        },
        {
          title: "用户名",
          dataIndex: "username"
        },
        {
          title: "申请后剩余余额",
          dataIndex: "balance"
        },
        {
          title: "可提现金额",
          dataIndex: "balanceWithdrawabl"
        },
        {
          title: "历史提现",
          dataIndex: "balanceRefundAll"
        },
        {
          title: "当前申请金额",
          dataIndex: "balanceRefundIn",
          scopedSlots: { customRender: "balanceRefundIn" }
        },
        {
          title: "支付宝登录名",
          dataIndex: "alipayAccount"
        },
        {
          title: "支付宝真实姓名",
          dataIndex: "alipayName"
        },
        {
          title: "申请理由",
          dataIndex: "balanceRefundRemark"
        },
        {
          title: "申请提现时间",
          dataIndex: "balanceRefundCashTime",
          customRender: formatDate
        },
        {
          title: "操作",
          dataIndex: "id",
          scopedSlots: { customRender: "operation" }
        }
      ],
      dataSource: parameter => {
        return queryWithdrawListApi(
          Object.assign(parameter, this.queryParam)
        ).then(result => {
          result.data.list.forEach(item => (item.show = false));
          return result.data;
        });
      }
    };
  },
  components: {
    PageView,
    STable
  },
  methods: {
    handleMouseover(record) {
      record.show = true;
    },
    handleMouseout(record) {
      record.show = false;
    },

    pass(record, payMethod) {
      let { edit } = this;
      const { $message, $refs } = this;
      this.record = JSON.parse(JSON.stringify(record))
      edit = true;
      record.payMethod = payMethod;
      // 通过
      if (payMethod === "BALANCE_REFUND") {
        const data = {
          payMethod,
          comment: record.balanceRefundRemark,
          uid: record.uid
        };
        handleWithdrawResult(data).then(result => {
          if (result.success) {
            $message.success("操作成功");
            $refs.table.refresh(false);
          } else {
            $message.error(result.msg);
          }
        });
      } else {
        this.visible = true;
      }
      edit = false;
    },

    resetQueryParam() {
      this.queryParam = {};
      this.$refs.table.refresh(false);
    },
    handleCancel() {
      this.visible = false;
      this.comment = "";
    },
    handleOk() {
      let { edit, comment } = this;
      const { $message, $refs, record } = this;
      edit = true;
      const data = {
        payMethod: 'BALANCE_REFUND_FAILURE',
        comment,
        uid: record.uid
      };
      handleWithdrawResult(data).then(result => {
        if (result.success) {
          $message.success("操作成功");
          $refs.table.refresh(false);
          this.handleCancel()
        } else {
          $message.error(result.msg);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.row {
  margin: 0 !important;
}
.cash_header_wrapper {
  padding: 24px 40px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
}
.input {
  width: 10px;
  opacity: 0;
}

.cash_table_wrapper {
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  padding: 24px;
  border-radius: 4px;
}

.input_group {
  display: flex;
  span {
    margin-top: 5px;

    text-align: right;
  }
}

.top5 {
  width: 240px;
  height: 32px;
}

.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
</style>
